import React from "react";
import "./Hero.css";

import dc from "../../images/head.png";

const Hero = () => {
  return (
    <div className="HeroSuper" id="hero">
      <div className="Herocontainer">
        <h1>
          <span>SunTrump</span>:
        </h1>
        <h2>How does it work?</h2>
        <p>
          suntrump aims to provide everyone with the opportunity to participate
          in Decentralized Finance (DeFi).
          <br />
          <br /> We took inspiration from industry leaders in the booming DeFi
           space and want to create our own innovation memetoken on Tron blockchain.
          We will provide a long-term sustainable environment that
          incentivizes long-term investments. <br />
        </p>
        <div className="HeroBtnContainer">
          {/* <a
            href="https://raydium.io/swap?inputCurrency=sol&outputCurrency=3DbnknoW4iRJomgF2Vv1iuLa1Qc6jasKAFLeVL9Dag3B&outputSymbol=Astro&fixed=in"
            target="_blank"
          >
            <div className="HeroBtn">
              <img src={dc} /> BUY $ASTRO
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
